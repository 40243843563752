<template>
  <section>
      <div class="content-header">
        <h2>“Boosting Student Resilience with Dr. Diana Brecher”</h2>
      </div>
      <div class="content-wrapper">
        <p><a href="https://scottbarrykaufman.com/podcast/" target="_blank">The Psychology Podcast with Scott Barry Kaufman</a> explores the depths of human potential through conversations with inspiring and thought-provoking guests who will stimulate your mind and give you a greater understanding of yourself, others, and the world we live in. </p>
        <p>In <a href="https://scottbarrykaufman.com/podcast/boosting-student-resilience-w-dr-diana-brecher/" target="_blank">this episode</a>, Dr. Brecher shares ways to increase resiliency, mindfulness, character strengths, self-efficacy, optimism, gratitude and more. </p>
        <p>Coming in at around 30 minutes, this is an excellent (and quick!) opportunity for you to hear from a leading expert in student resilience.</p>
        <!--<div class="audio-player" ref="parentDiv">
          <p>Boosting Student Resilience with Dr. Diana Brecher</p>
           <vue-plyr>
            <audio controls crossorigin playsinline ref="foo">
              src="@/assets/audio/personal/09.mp3"
              <source
                src="https://staging.gevc.ca/centennial/tic/videos/academic/09.mp3"
                type="audio/mp3"
              />
            </audio>
          </vue-plyr>
          <av-line
            v-if="doRender"
            :canv-width="cW"
            :line-width="1"
            line-color="#39B54A"
            ref-link="foo"
          ></av-line> -->
        </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
